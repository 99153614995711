<template>
  <div class="inner-pages hd-white">
    <div id="wrapper">
      <div id="login">
        <div class="login">
          <form @submit.prevent="" autocomplete="off">
            <div class="form-group">
              <label>Username</label>
              <input
                :class="
                  $v.username.$dirty && !$v.username.required
                    ? 'error-border'
                    : ''
                "
                v-model="username"
                class="form-control"
                type="text"
              />
              <span
                class="error"
                v-if="$v.username.$dirty && !$v.username.required"
                >This field is required!</span
              >
              <span class="error" v-if="usernameError"
                >This username has already been taken!</span
              >
              <i class="ti-user"></i>
            </div>
            <div id="pass-info" class="clearfix"></div>
            <button
              @click="add()"
              style="outline: none"
              class="btn_1 rounded full-width add_top_30"
            >
              Add
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  mounted() {},
  computed: {
    ...mapGetters({
      authUser: "auth",
      buyerSeller: "getBuyerSeller",
      check: "check",
    }),
  },
  data() {
    return {
      hasClicked: false,
      usernameError: false,
      username: "",
      accessToken: "",
    };
  },
  validations: {
    username: {
      required,
    },
  },
  watch: {
    username() {
      if (this.hasClicked) this.usernameError = false;
    },
  },
  methods: {
    async login() {
      await this.$store.dispatch("getUser").then((res) => {
        this.$store.commit("setShowLoader", false);
        if (res == true) {
          Object.keys(this.$route.query).includes("welcome-flag")
            ? this.$router.push("/welcome")
            : this.$router.push("/");
        } else {
          this.$router.push("/login");
        }
      });
    },
    async add() {
      if (this.$v.$invalid) {
        if (this.$v.username.$invalid) this.$v.username.$touch();
      } else {
        this.hasClicked = true;
        this.$store.commit("setShowLoader", true);
        this.accessToken = this.$router.currentRoute.query.access_token;
        if (_.size(this.accessToken))
          localStorage.setItem("buyer_access_token", this.accessToken);
        const data = {
          user_name: this.username,
        };
        this.$store.dispatch("addUserName", data).then((res) => {
          if (res == true) {
            if (!this.check) {
              if (_.size(this.accessToken)) this.login();
            } else {
              this.$store.dispatch("getUser")
              this.$store.commit("setShowLoader", false);
              this.$router.push("/");
            }
          } else {
            this.$store.commit("setShowLoader", false);
            this.usernameError = true;
          }
        });
      }
    },
  },
};
</script>

<style scoped src="../assets/css/fontawesome-all.css"></style>
<style scoped src="../assets/css/fontawesome-5-all.min.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/styles.css"></style>

<style scoped>
</style>
